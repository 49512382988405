import { Button, Dialog, DialogBody, DialogFooter, DialogHeader, Typography } from "@material-tailwind/react"
import * as animationData from "./anim.json"
import Lottie from 'react-lottie';
import { useEffect, useRef, useState } from "react";
import Confetti, { ConfettiRef } from "./Confetti";

type SuccessProps = {
    open: boolean;
    onClose: () => void;
}
const SuccessDialog: React.FC<SuccessProps> = ({open, onClose}) => {
    const [isPaused, setPaused] = useState<boolean>(true);

    const defaultOptions = {
        loop: false,
        autoplay: false, 
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
    if (!open)
        return;

    setTimeout(() => {setPaused(false);}, 200)
    }, [open])

    const confettiRef = useRef<ConfettiRef>(null);

    const handleShootConfetti = () => {
        if (confettiRef.current) {
          confettiRef.current.shoot();
        }
      };

      useEffect(() => {if (open) handleShootConfetti()}, [open])

    return (
        <>
        <Dialog
            open={open}
            handler={() => {onClose()}}
            animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: 100 },
            }}
        >
            <div className="z-50">
                <Confetti ref={confettiRef}/>
            </div>
            <div>
                <div className="">
                    <Lottie options={defaultOptions}
                    height={200}
                    width={200}
                    isPaused={isPaused}
                    
                    />
                </div>
            
            </div>
            <div className="text-[#2BAF63] font-bold text-2xl w-full text-center">Request Submitted</div>
            <div className="w-full text-center mt-2">Your enquiry has been safely delivered to our dedicated support team. </div>
            <div className="w-full text-center">Enjoy some confetti! </div>

            <DialogFooter className="w-full mt-8 justify-center">
            <Button variant="text" color="black" className="w-36 text-[16px]" onClick={handleShootConfetti}>
                <span>Celebrate</span>
            </Button>
            </DialogFooter>
        </Dialog>
        </>
    )
}

export default SuccessDialog;
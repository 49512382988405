import { Button, Input } from "@material-tailwind/react";
import axios from "axios";
import { useEffect, useState } from "react";

const loadGoogleMapsScript = (callback: () => void) => {
  const existingScript = document.getElementById('googleMaps');

  if (!existingScript) {
      const script = document.createElement('script');
      const apiKey = process.env.REACT_APP_PLACES_API;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.id = 'googleMaps';
      document.body.appendChild(script);

      script.onload = () => {
          if (callback) callback();
      };
  }

  if (existingScript && callback) callback();
};


const TestOrder: React.FC = () => {
    const [addressLine1, setAddressLine1] = useState<string>("");

    useEffect(() => {
        loadGoogleMapsScript(() => {
          // @ts-ignore
            if (window.google) {
              // @ts-ignore
                const autocomplete = new window.google.maps.places.Autocomplete(
                    document.getElementById('addressLine1') as HTMLInputElement,
                    { types: ['address'], componentRestrictions: { country: 'AU' } } // Restrict to Australia
                );
                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    console.log(place);
                    if (place.address_components) {
                        setAddressLine1(place.formatted_address);
                        handlePlaceChange(place);
                    }
                });
            }
        });
    }, []);

    const handlePlaceChange = (place: any) => {
  
      let streetNumber = '';
      let route = '';
      let city = '';
      let state = '';
      let zipCode = '';
  
      // Loop through the address components
      place.address_components.forEach((component: any) => {
          const componentType = component.types[0];
  
          switch (componentType) {
              case 'street_number':
                  streetNumber = component.long_name;
                  break;
              case 'route':
                  route = component.long_name;
                  break;
              case 'locality': // City
                  city = component.long_name;
                  break;
              case 'administrative_area_level_1': // State
                  state = component.long_name;
                  break;
              case 'postal_code': // ZIP Code
                  zipCode = component.short_name;
                  break;
              default:
                  break;
          }
      });
  
      // Construct address by combining street number and route
      const address = `${streetNumber} ${route}`.trim();
  
      console.log("Address:", address);
      console.log("City:", city);
      console.log("State:", state);
      console.log("ZIP Code:", zipCode);
  };
  
  const fetchOrder = async () => {
    const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";

    try {
        const response = await axios.get(`${apiURL}/testOrder`);

    } catch (error) {
        console.error("Error fetching order:", error);
    }
};

    return (
        <div className="w-full flex items-center justify-center">
            <div className="w-[400px] flex flex-col gap-4 mt-8">
              <div className="">Waddup Harry</div>
              <Input id="addressLine1" label="Address Line 1" onChange={(e) => setAddressLine1(e.target.value)} value={addressLine1} />
              <Button variant="gradient" className="mt-2" onClick={fetchOrder}>Test Email</Button>
            </div>
        </div>
    );
};

export default TestOrder;
import { Button, Card } from "@material-tailwind/react";
import { TrashIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import axios from "axios";
import { Build, CartDisplayItemJSON, CartResponseItem } from "../types";

interface CartItemProps {
  cartID: string;
  responseItem: CartResponseItem;  // Assuming `CartResponseItem` type here
  onDelete: () => void;  
}

const CartItem: React.FC<CartItemProps> = ({ cartID, responseItem, onDelete }) => {
  const [item, setItem] = useState<CartDisplayItemJSON>();

  useEffect(() => {
    if (responseItem) setItem(responseItem.json);
  }, [responseItem]);

  const removeSelf = () => {
    const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";
    
    const handleAPICall = async () => {
      try {
        const response = await axios.post(`${apiURL}/deleteCartItem`, { cartID, cartItemID: responseItem.itemid });
        if (response.status === 200) {
          onDelete();  // Call the onDelete callback to trigger a re-fetch
        }
      } catch (err) {
        console.error('Error deleting item:', err);
      }
    };
    handleAPICall();
  };

  const [build, setBuild] = useState<Build>();
  
  useEffect(() => {
    const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";

    if (!item)
        return;

    const handleClick = async () => {
        try {
            const response = await axios.post(`${apiURL}/getPrice`, {
                min: item?.minimumRequirements,
                case: item?.selectedCase,
                variant: item?.caseVariant,
            }
            );
            setBuild(response.data.build);
        } catch (err) {
        console.error('Error creating checkout session:', err);
        }
    };
    handleClick();
  }, [item])
    

  if (!item ) {
    return <div></div>;
  }

  return (
    <Card className="flex flex-row text-[#212121] items-center">
      <img src={item.caseVariant.images[0]} width={170} alt="Case Variant" />
      <div className="flex flex-col">
        <div className="font-semibold text-[20px] mt-4 text-center">{item.selectedCase.name}</div>
        <div className="font-medium text-[16px]">Case Variant: <span className="font-normal">{item.caseVariant.name}</span></div>
        <div className="mt-4" />
        <div className="grid grid-cols-2 grid-rows-2 gap-x-4 gap-y-2">
          <div className="font-medium text-[16px]">CPU: <span className="font-normal">{item.parts.cpu}</span></div>
          <div className="font-medium text-[16px]">GPU: <span className="font-normal">{item.parts.gpu}</span></div>
          <div className="font-medium text-[16px]">RAM: <span className="font-normal">{item.minimumRequirements.ram}GB</span></div>
          <div className="font-medium text-[16px]">Storage: <span className="font-normal">{item.minimumRequirements.storage}TB</span></div>
        </div>
      </div>
      <div className="ml-auto m-4 flex flex-col items-center justify-center h-full">
        <div className="text-[32px] font-bold mr-4">${build?.price}.00</div>
        <Button color="red" variant="outlined" className="flex flex-row items-center gap-1 mb-[-8px]" onClick={removeSelf}>
          <TrashIcon className="h-4 mt-[-2px]" /> Remove
        </Button>
      </div>
    </Card>
  );
};

export default CartItem;

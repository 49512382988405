import { useLocation } from "react-router-dom";
import { ComplexNavbar } from "../../components/ComplexNavbar";
import { Footer } from "../../components/Footer";
import { useEffect } from "react";

const ToS: React.FC = () => {
    const location = useLocation();
    const offset = 100;

    useEffect(() => {
        if (location.hash) {
            const element = document.querySelector(location.hash);
            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            }
        }
    }, [location]);

    return (
        <div className="xl:mt-4 flex flex-col items-center justify-center h-full">
            <ComplexNavbar />

            {/* CONTENT */}
            <div className={`max-w-screen-xl w-full px-4 h-full mt-8`}>
                <div className="font-bold text-2xl">
                    Terms Of Service
                </div>
                <div className="mt-2"></div>
                <p><strong>Effective Date:</strong> 16/10/2024</p>
                <p>Welcome to Adrift Computers! These Terms of Service govern your use of our website and services for purchasing custom-built and prebuilt computers. By using our site, you agree to be bound by the following terms and conditions. Please read them carefully.</p>

                <br/>

                <h2 className="text-xl font-bold">1. General Terms</h2>
                <p><strong>1.1.</strong> By accessing or using our website, you confirm that you are at least 18 years old or have the permission of a legal guardian to use our services.</p>
                <p><strong>1.2.</strong> We reserve the right to update these Terms of Service at any time without prior notice. Your continued use of the website constitutes acceptance of any changes.</p>

                <br/>

                <h2 className="text-xl font-bold">2. Products and Orders</h2>
                <p><strong>2.1.</strong> All systems are subject to availability. We strive to keep product listings accurate, but errors in descriptions, pricing, or availability may occur.</p>
                <p><strong>2.2.</strong> Prices are subject to change without notice. Once an order is confirmed, the price is final unless modified by mutual agreement.</p>
                <p><strong>2.3.</strong> Orders may be canceled by us if there are stock issues, technical errors, or suspicious activities. You will be notified promptly in such cases.</p>

                <br/>

                <h2 className="text-xl font-bold">3. Customization and Build Process</h2>
                <p><strong>3.1.</strong> Custom orders are built to the recommended specifications of the applications and games selected by you. It is your responsibility to ensure that the selected applications and games are accurate to your usage.</p>
                <p><strong>3.2.</strong> Production and shipping times are estimates and may vary due to supply chain delays or unforeseen circumstances.</p>

                <br/>

                <h2 className="text-xl font-bold">4. Payments and Billing</h2>
                <p><strong>4.1.</strong> We accept major credit cards, PayPal, and other payment methods displayed on our site. All transactions are processed securely.</p>
                <p><strong>4.2.</strong> Orders will not be processed until payment is confirmed. If your payment is declined, the order will be canceled automatically.</p>
                <p><strong>4.3.</strong> Any applicable taxes and shipping fees will be added to your order during checkout.</p>

                <br/>

                <h2 className="text-xl font-bold">5. Shipping and Delivery</h2>
                <p><strong>5.1.</strong> We ship to Australia only. Delivery times may vary.</p>
                <p><strong>5.2.</strong> We are not responsible for delays caused by third-party carriers or customs.</p>
                <p><strong>5.3.</strong> Our delivery provider is Team Global Express and Team Global Express’s subcontractors.</p>
                <p><strong>5.4.</strong> Risk of loss transfers to you upon delivery to the shipping carrier.</p>

                <br/>

                <section id="returns">
                    <h2 className="text-xl font-bold">6. Returns and Refunds</h2>
                    <p><strong>6.1.</strong> All systems are non-refundable unless there is a defect or issue caused by Adrift Computers.</p>
                    <p><strong>6.2.</strong> All returns must be authorized through our <a href="#">Return Merchandise Authorization (RMA)</a> process.</p>
                    <p><strong>6.3.</strong> In the event of a defective or faulty product caused by Adrift Computers, you will receive an RMA number and instructions on how to package and ship the item back.</p>
                    <p><strong>6.4.</strong> Items returned without an RMA number may be rejected or cause delays.</p>
                </section>
                

                <br/>

                <section id="warranty">
                    <h2 className="text-xl font-bold">7. Warranty and Support</h2>
                    <p><strong>7.1. Manufacturer’s Warranty on Components</strong></p>
                    <ul>
                        <li>Many of the components used in our systems, including CPUs, GPUs, motherboards, and storage devices, are covered by manufacturer warranties.</li>
                        <li>If a component within your system experiences issues, you are required to contact the component manufacturer directly as the first step in troubleshooting or warranty claims. This ensures faster service and minimizes delays in resolving the issue.</li>
                        <li>Manufacturers often provide technical support, repair, or replacement services. You may need to supply the component’s serial number or proof of purchase (which we can assist with upon request).</li>
                    </ul>
                    <p><strong>7.2. When to Contact Adrift Computers</strong></p>
                    <ul>
                        <li>If the issue cannot be resolved through the manufacturer, or if the manufacturer confirms the issue is outside their warranty coverage, please contact our support team at <a href="mailto:support@adrift.dev">support@adrift.dev</a>.</li>
                        <li>We will assist you with further troubleshooting and coordinating repairs or replacements, provided the product is within the warranty period outlined in 7.3.</li>
                    </ul>
                    <p><strong>7.3. Adrift Computers Limited Warranty</strong></p>
                    <ul>
                        <li>In addition to manufacturer warranties, Adrift Computers offers a 6-month limited warranty on all systems. This warranty covers defects in assembly and components not otherwise covered by the manufacturer.</li>
                        <li><strong>Our warranty does not cover:</strong>
                            <ul>
                                <li>Physical damage caused by misuse, accidents, or unauthorized modifications.</li>
                                <li>Damage from power surges, overclocking, or environmental factors.</li>
                                <li>Issues resolved directly by the manufacturer under their warranty.</li>
                            </ul>
                        </li>
                    </ul>
                </section>
                

                <br/>

                <h2 className="text-xl font-bold">8. Limitation of Liability</h2>
                <p><strong>8.1.</strong> We are not liable for any indirect, incidental, or consequential damages arising from your use of our products or services.</p>
                <p><strong>8.2.</strong> Our total liability shall not exceed the amount you paid for the product.</p>

                <br/>

                <h2 className="text-xl font-bold">9. Privacy Policy</h2>
                <p><strong>9.1.</strong> Your use of our site is also governed by our <a href="#">Privacy Policy</a>. Please review it to understand how we collect, use, and protect your information.</p>

                <br/>

                <h2 className="text-xl font-bold">10. Intellectual Property</h2>
                <p><strong>10.1.</strong> All content, designs, and trademarks on this site are the property of Adrift Computers OR the appropriate IP holder and are protected by copyright and intellectual property laws.</p>
                <p><strong>10.2.</strong> You may not reproduce, modify, or distribute any content found on our site.</p>

                <br/>

                <h2 className="text-xl font-bold">11. Governing Law and Dispute Resolution</h2>
                <p><strong>11.1.</strong> Any dispute arising out of or related to these Terms and Conditions or the sales transaction between Adrift Computers and Customer shall be governed by the laws of the State of New South Wales or as defined by the Australian Consumer Law.</p>

                <br/>

                <h2 className="text-xl font-bold">12. Termination</h2>
                <p><strong>12.1.</strong> We may terminate or suspend your access to our services at our discretion, with or without notice, for violations of these Terms or other unlawful activity.</p>
                <p><strong>12.2.</strong> Upon termination, your right to use the site will immediately cease.</p>

                <br/>

                <h2 className="text-xl font-bold">13. Contact Information</h2>
                <p>If you have any questions or concerns about these Terms of Service, please contact us at:</p>
                <p>Email: <a href="mailto:support@adrift.dev">support@adrift.dev</a></p>

                <br/>

                <p>By using Adrift Computers, you acknowledge that you have read, understood, and agreed to these Terms of Service. Thank you for shopping with us!</p>
            </div>

            <div className="mt-8 w-full border-[#21211] border-t-2">
                <div className="mt-4">
                    <Footer/>
                </div>
            </div>
        </div>
    )
}

export default ToS;
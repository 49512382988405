import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Navbar, Spinner } from "@material-tailwind/react";
import Loading from "../components/Loading";
import Login from "../components/Login";
import { ComplexNavbar } from "../components/ComplexNavbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CaseVariant } from "../components/steps/Case";
import { Build, Cart, CartCheckoutData, CartResponse, Case, MinimumRequirements } from "../types";
import CartItem from "../components/CartItem";
import { Footer } from "../components/Footer";

function CartPage() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [cartData, setCartData] = useState<CartResponse>();

  const [minimumRequirements, setMinimumRequirements] = useState<MinimumRequirements[]>([]);

  const cartID = searchParams.get("cartID") as string;

  const [emptyCart, setEmptyCart] = useState<boolean>(false);

  // Function to fetch the cart data
  const getCartData = (cartID: string) => {            
    const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";

    const handleAPICall = async () => {
      try {
        const response = await axios.get(`${apiURL}/getCart/${cartID}`);
        setCartData(response.data.data);
      } catch (err) {
        console.error('Error fetching cart data:', err);
      }
    };
    handleAPICall();
  }

  useEffect(() => {
    getCartData(cartID);
  }, [cartID]);

  // Callback function to re-fetch cart data after an item is deleted
  const handleItemDeleted = () => {
    getCartData(cartID); 
  };

  useEffect(() => {
    if (cartData?.items.length === 0) {
        setEmptyCart(true);
    }
  }, [cartData]);

  const checkout = () => {
    // get all the builds
    
    // send them to backend
    const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";

    const handleAPICall = async () => {
      try {
        const data: CartCheckoutData[] = [];
        cartData?.items.forEach(item => data.push({min: item.json.minimumRequirements, variant: item.json.caseVariant, selectedCase: item.json.selectedCase}));

        const response = await axios.post(`${apiURL}/checkoutCart`, {checkoutData: data});
        console.log(response);
        if (response.data.url) {
            window.location.href = response.data.url;
        }
      } catch (err) {
        console.error('Error fetching cart data:', err);
      }
    };
    handleAPICall();
  }

  if (!cartData) {
    return <Loading />;
  }

  return (
    <div className="h-screen">
      <div className='flex justify-center xl:pt-4'>
        <ComplexNavbar />
      </div>
      <div className="xl:mt-4 flex flex-col items-center justify-center h-full">
        <div className="max-w-screen-xl w-full px-4 h-full mt-4">
          <div className="font-medium text-[24px]">Cart</div>
          <div className="mt-2 flex flex-col">
            <div><span className="font-medium">CartID:</span> <span className="font-normal text-blue-gray-700">{cartID}</span></div>
            {cartData && cartData.items && (
              <div className="mt-4 flex flex-col gap-4">
                {cartData.items.map(item => (
                  <CartItem 
                    key={item.itemid}
                    cartID={cartID} 
                    responseItem={item}
                    onDelete={handleItemDeleted}  // Pass the callback to each CartItem
                  />
                ))}
              </div>
            )}
            {emptyCart &&
            <>
                <div className="w-full flex flex-col gap-2 items-center justify-center text-[#0d0d0d]">
                    <div>There's nothing here...</div>
                    <Button onClick={() => {navigate("/");}}>Add Something</Button>
                </div>
            </>
            }
            {!emptyCart && <>
                <div className="mt-8 ml-auto">
                    <Button size="lg" onClick={checkout}>Checkout</Button>
                </div>
            </>}
          </div>
        </div>
      </div>
      <div className="mt-auto w-full border-[#21211] border-t-2">
        <div className="mt-4">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default CartPage;

import { ComplexNavbar } from "../../components/ComplexNavbar";
import { Footer } from "../../components/Footer";

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="xl:mt-4 flex flex-col items-center justify-center h-full">
            <ComplexNavbar />

            {/* CONTENT */}
            <div className={`max-w-screen-xl w-full px-4 h-full mt-8`}>
                <div className="font-bold text-2xl">
                    Privacy Policy
                </div>
                <div className="mt-2"></div>
                <p><strong>Effective Date:</strong> 16/10/2024</p>

<p>Adrift Computers is committed to protecting your privacy and ensuring that your personal information is handled securely and responsibly. This Privacy Policy explains how we collect, use, store, and share your information when you use our website and services, including purchases made through our online store. By using our site, you agree to the practices described in this policy.</p>

<br/>

<h2 className="text-xl font-bold">1. Information We Collect</h2>
<p>When you interact with Adrift Computers, we may collect the following types of information:</p>

<p><strong>1.1</strong>. Personal Information</p>
<ul>
    <li>- Name, email address, phone number, shipping address, and billing address</li>
    <li>- Payment information (processed securely through Stripe; Adrift Computers does not store your full credit card details)</li>
</ul>

<p><strong>1.2.</strong> Order Information</p>
<ul>
    <li>- Products ordered, order history, and relevant communications</li>
    <li>- Serial numbers for warranty claims when applicable</li>
</ul>

<br/>

<h2 className="text-xl font-bold">2. How We Use Your Information</h2>
<p>We collect and use your information to:</p>
<ul>
    <li>- Process and fulfill orders, including shipping and payment transactions</li>
    <li>- Provide customer support, including handling returns, warranty claims, and RMAs</li>
    <li>- Communicate with you regarding your orders</li>
    <li>- Ensure compliance with legal and regulatory requirements</li>
</ul>

<br/>

<h2 className="text-xl font-bold">3. Payment Processing with Stripe</h2>
<p>We use Stripe to process payments securely. Stripe collects your payment information (such as credit card numbers) directly through their platform. Adrift Computers does not store or have access to your full payment details.</p>
<p>Stripe complies with the Payment Card Industry Data Security Standard (PCI-DSS) to ensure your information is protected. For more information, please review <a href="https://stripe.com/privacy">Stripe’s Privacy Policy (https://stripe.com/au/privacy)</a>.</p>

<br/>

<h2 className="text-xl font-bold">4. How We Share Your Information</h2>
<p>We do not sell or rent your personal information to third parties. However, we may share your information with trusted third parties for the following purposes:</p>
<ul>
    <li>- Payment Processors: Stripe handles all payment transactions securely.</li>
    <li>- Shipping Carriers: To deliver your orders.</li>
    <li>- Manufacturers and Distributors: For warranty claims and RMAs when necessary.</li>
    <li>- Legal Requirements: If required by law or to protect the rights of Adrift Computers.</li>
    <li>- Service Providers: Hosting providers, analytics tools, and email service platforms that help us operate our business.</li>
</ul>
<p>All third parties we work with are required to handle your information in compliance with applicable privacy laws.</p>

<br/>

<h2 className="text-xl font-bold">5. Data Retention</h2>
<p>We retain your personal information only as long as necessary to fulfill the purposes outlined in this policy, including legal, tax, or accounting obligations. Once your information is no longer needed, it will be securely deleted or anonymized.</p>

<br/>

<h2 className="text-xl font-bold">6. Your Data Rights</h2>
<p>Depending on your location, you may have certain rights regarding your personal data, including:</p>
<ul>
    <li><strong>Access:</strong> Request a copy of the personal data we hold about you.</li>
    <li><strong>Correction:</strong> Request corrections to inaccurate information.</li>
    <li><strong>Deletion:</strong> Request deletion of your data, subject to legal requirements.</li>
</ul>
<p>To exercise these rights, please contact us at <a href="mailto:support@adrift.dev">support@adrift.dev</a>. We will respond to your request within the timeframe required by law.</p>

<br/>

<h2 className="text-xl font-bold">7. Data Security</h2>
<p>We take reasonable measures to protect your personal information from unauthorized access, loss, or misuse. This includes encryption of sensitive data and access restrictions. However, no online system is 100% secure, and we encourage you to take steps to protect your information, such as using strong passwords.</p>

<br/>

<h2 className="text-xl font-bold">8. Third-Party Links</h2>
<p>Our website may contain links to third-party websites or services. We are not responsible for the privacy practices of these external sites, and we encourage you to review their privacy policies before providing any personal information.</p>

<br/>

<h2 className="text-xl font-bold">9. Children’s Privacy</h2>
<p>Our website is not intended for use by children under 18, and we do not knowingly collect personal information from children. If we become aware that we have inadvertently collected information from a child, we will take steps to delete it.</p>

<br/>

<h2 className="text-xl font-bold">10. Changes to this Privacy Policy</h2>
<p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. When we make changes, we will post the updated policy on our website with the new effective date. Your continued use of the site after any changes indicates your acceptance of the revised policy.</p>

<br/>

<h2 className="text-xl font-bold">11. Contact Us</h2>
<p>If you have any questions or concerns about this Privacy Policy or how we handle your personal information, please contact us:</p>
<p>Email: <a href="mailto:support@adrift.dev">support@adrift.dev</a></p>

<br/>

<p>By using Adrift Computers, you agree to the terms of this Privacy Policy. Thank you for trusting us with your personal information.</p>
            </div>

            <div className="mt-8 w-full border-[#21211] border-t-2">
                <div className="mt-4">
                    <Footer/>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;
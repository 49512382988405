import React, { useEffect, useState } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  MenuItem,
  IconButton,
  Collapse,
  MenuList,
  Menu,
  MenuHandler,
  Avatar,
} from "@material-tailwind/react";
import {
  Bars2Icon,
  HomeIcon,
  IdentificationIcon,
  EnvelopeIcon,
  ChevronDownIcon,
  UserCircleIcon,
  LifebuoyIcon,
  PowerIcon,
  ShoppingCartIcon
} from "@heroicons/react/24/solid";
import { useAuth0 } from "@auth0/auth0-react";
import { useFetcher, useNavigate } from "react-router-dom";

// nav list component
const navListItems = [
  {
    label: "Home",
    icon: HomeIcon,
    href: "/"
  },
  {
    label: "About Us",
    icon: IdentificationIcon,
    href: "/about"
  },
  {
    label: "Contact",
    icon: EnvelopeIcon,
    href: "/contact"
  },
];

const profileMenuItems = [
  {
    label: "My Profile",
    icon: UserCircleIcon,
  },
  {
    label: "Help",
    icon: LifebuoyIcon,
  },
  {
    label: "Sign Out",
    icon: PowerIcon
  },
];

interface NavbarProps {
  profile?: string | undefined;
}

const ProfileMenu: React.FC<NavbarProps> = ({ profile }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
 
  const closeMenu = () => setIsMenuOpen(false);
 
  const { logout } = useAuth0();
  const navigate = useNavigate();

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
        >
          <Avatar
            variant="circular"
            size="sm"
            className="border border-gray-900 p-0.5"
            src={profile}
          />
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3 w-3 transition-transform ${
              isMenuOpen ? "rotate-180" : ""
            }`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="p-1">
        {profileMenuItems.map(({ label, icon }, key) => {
          const isLastItem = key === profileMenuItems.length - 1;
          return (
            <MenuItem
              key={label}
              onClick={() => {
                if (label.toLowerCase().includes("out")) {
                  logout();
                } else if (label === "My Profile") {
                  navigate("/account");
                } else if (label === "Help") {
                  navigate("/contact");
                } else {
                  closeMenu();
                }
              }}
              className={`flex items-center gap-2 rounded ${isLastItem
                  ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10"
                  : ""}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
              {React.createElement(icon, {
                className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                strokeWidth: 2,
              })}
              <Typography
                as="span"
                variant="small"
                className="font-normal"
                color={isLastItem ? "red" : "inherit"}
              >
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}

function NavList() {
  return (
    <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
      {navListItems.map(({ label, icon, href }, key) => (
        <Typography
          key={label}
          as="a"
          href={href}
          variant="small"
          color="gray"
          className="font-medium text-blue-gray-500"
        >
          <MenuItem className="flex items-center gap-2 lg:rounded-full">
            {React.createElement(icon, { className: "h-[18px] w-[18px]" })}{" "}
            <a className="text-gray-900" href={href}> {label}</a>
          </MenuItem>
        </Typography>
      ))}
      <div className="text-black lg:hidden">TODO: 'Dashboard' Page For Mobile</div>
    </ul>
  );
}

export function ComplexNavbar() {
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const navigate = useNavigate()

  const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false)
    );
  }, []);

  const { user, isLoading } = useAuth0();

  // Handle caching user profile picture
  let cachedProfile = "https://www.ledr.com/colours/white.jpg";

  if (localStorage.getItem("profilePicture")) {
    cachedProfile = localStorage.getItem("profilePicture") as string;
    console.log("Profile is cached")
  }
  const [profilePicture, setProfilePicture] = useState<string>(cachedProfile);
  
  const goToCart = () => {
    console.log("Going to Cart?");
    if (localStorage.getItem('cartID') && localStorage.getItem('cartID') !== "") {
      console.log(`Stored CartID Found: ${localStorage.getItem('cartID')}`)
      navigate(`/cart?cartID=${localStorage.getItem('cartID')}`)
    }
    
  }

  useEffect(() => {
    if (!isLoading && user && user.picture) {
      setProfilePicture(user.picture);
      localStorage.setItem("profilePicture", user.picture)
    } else if (!isLoading) {
      setProfilePicture("https://i.pinimg.com/originals/ac/11/aa/ac11aa2add3b0193c8769e0a17d13535.jpg");
    }
  }, [user, isLoading])

  return (
    <Navbar className="sticky top-0 xl:top-3 z-50 bg-white mx-auto max-w-screen-xl p-2 lg:rounded-full lg:pl-6">
      <div className="relative mx-auto flex items-center justify-between text-"></div>
      
      <div className="absolute xl:top-3 left-6 p-2">
        <a href="/">
          <img src={"/Banner.png"} alt="AdriftComputers" width={188} />
        </a>
      </div>
      <div className="mx-auto flex items-center justify-center text-blue-gray-900">
        <div className="hidden lg:block">
          <NavList />
        </div>
        <IconButton
          size="sm"
          color="blue-gray"
          variant="text"
          onClick={toggleIsNavOpen}
          className="ml-auto mr-2 lg:hidden"
        >
          <Bars2Icon className="h-6 w-6" />
        </IconButton>

        <Button size="sm" variant="text">
          <></>
        </Button>

        <div className="absolute right-20 p-2">
          <IconButton
            size="sm"
            variant="text"
            onClick={goToCart}
          >
            <ShoppingCartIcon className="h-6 w-6" />
          </IconButton>
        </div>
        <div className="absolute right-0 p-2">
          <ProfileMenu profile={profilePicture}/>
        </div>
      </div>
      
      <Collapse open={isNavOpen} className="overflow-scroll">
        <NavList />
      </Collapse>
    </Navbar>
  );
}

import { Button, Card, Input, Select, Option } from "@material-tailwind/react";
import { CaseSelection } from "../../pages/CreateBuild";
import { Build, CustomerInformation, ShippingOption } from "../../types";
import { useEffect, useState } from "react";
import axios from "axios";
import { CaseVariant } from "./Case";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { ExclamationTriangleIcon }  from "@heroicons/react/24/solid";
import { DefaultSkeleton } from "../DefaultSkeleton";

type CheckoutProps = {
    build: Build;
    ram: number;
    storage: number;
    price: number;
    selectedCase: CaseSelection;
    onPurchase: (customer: CustomerInformation) => void;
}

const Checkout: React.FC<CheckoutProps> = ({ build, ram, storage, price, selectedCase, onPurchase }) => {
    const [selectedImage, setSelectedImage] = useState<string>("");
    const [variant, setVariant] = useState<CaseVariant>();

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");

    const [email, setEmail] = useState<string>("");
    const [validEmail, setValidEmail] = useState<boolean>(true);

    const [addressLine1, setAddressLine1] = useState<string>("");
    const [addressLine2, setAddressLine2] = useState<string>("");

    const [city, setCity] = useState<string>("");
    const [zip, setZIP] = useState<string>("");
    const [state, setState] = useState<string>();

    const [shippingOption, setShippingOption] = useState<ShippingOption>();

    const [validForm, setValidForm] = useState<boolean>(true);

    useEffect(() => {
        setVariant(selectedCase.variant);
        setSelectedImage(selectedCase.variant.images[0]);
    }, [selectedCase]);

    useEffect(() => {
        const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";

        if (zip && zip.length === 4) {
            const handleZipCodeSubmit = async () => {
                try {
                  const response = await axios.get(`${apiURL}/postage/${zip}`);
                  const option = response.data.option;

                  if (option) {
                    setShippingOption(option);
                  } else {
                    console.log("Error Returning Options - Null")
                  }
                } catch (err) {
                    console.log(err);
                }
              };

            handleZipCodeSubmit();
        } else if (!zip || zip.length !== 4) {
            setShippingOption(undefined);
        }
    }, [zip])

    useEffect(() => {
        loadGoogleMapsScript(() => {
          // @ts-ignore
            if (window.google) {
              // @ts-ignore
                const autocomplete = new window.google.maps.places.Autocomplete(
                    document.getElementById('addressLine1') as HTMLInputElement,
                    { types: ['address'], componentRestrictions: { country: 'AU' } } // Restrict to Australia
                );
                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    console.log(place);
                    if (place.address_components) {
                        //setAddressLine1(place.formatted_address);
                        handlePlaceChange(place);
                    }
                });
            }
        });
    }, []);

    const handlePlaceChange = (place: any) => {
  
        let streetNumber = '';
        let route = '';
        let city = '';
        let state = '';
        let zipCode = '';
    
        // Loop through the address components
        place.address_components.forEach((component: any) => {
            const componentType = component.types[0];
    
            switch (componentType) {
                case 'street_number':
                    streetNumber = component.long_name;
                    break;
                case 'route':
                    route = component.long_name;
                    break;
                case 'locality': // City
                    city = component.long_name;
                    break;
                case 'administrative_area_level_1': // State
                    state = component.long_name;
                    break;
                case 'postal_code': // ZIP Code
                    zipCode = component.short_name;
                    break;
                default:
                    break;
            }
        });
    
        // Construct address by combining street number and route
        const address = `${streetNumber} ${route}`.trim();
    
        setAddressLine1(address);
        setCity(city);
        setState(state);
        setZIP(zipCode);

        console.log("Address:", address);
        console.log("City:", city);
        console.log("State:", state);
        console.log("ZIP Code:", zipCode);
    };
    
    const validateEmailAddress = () => {
        if (!email)
            return;

        const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const valid = regexp.test(email);

        setValidEmail(valid);
    }

    return (
        <>
            {variant &&
                <div className="mt-4">
                    <Card className={`text-[#212121] mb-16 h-full pb-4 w-full relative`}>
                        <div className="xl:flex xl:flex-row">
                            <div className="hidden xl:flex pt-4 xl:pt-0 px-4 flex-col gap-4">
                                <div className="flex flex-col xl:items-center ml-auto">
                                    <div className="flex justify-center xl:mt-4 xl:pl-4 max-h-[400px] min-w-[200px] xl:min-w-[400px]">
                                        <img src={selectedImage} className="rounded-lg max-h-[400px]" alt="Selected Case" />
                                    </div>
                                    {/*Image Scroller*/}
                                    <Card className="w-full mt-4 max-h-[100px] max-w-[400px] " style={{ height: 450 }}>
                                        <div className={`flex flex-row  gap-[10px] overflow-y-auto no-scrollbar`}>
                                            {variant.images.map((img, index) => (
                                                <img
                                                    key={index}
                                                    src={img}
                                                    alt=""
                                                    height={100}
                                                    className={`rounded-lg cursor-pointer max-w-[100px] max-h-[100px]`}
                                                    onClick={() => setSelectedImage(img)}
                                                />
                                            ))}
                                        </div>
                                    </Card>
                                </div>
                                <div className="text-[16px] xl:text-[24px] font-semibold">CPU: <span className="font-normal">{build.cpu.name}</span></div>
                                <div className="text-[16px] xl:text-[24px] font-semibold">GPU: <span className="font-normal">{build.gpu.name}</span></div>
                                <div className="text-[16px] xl:text-[24px] font-semibold">RAM: <span className="font-normal">{ram}GB</span></div>
                                <div className="text-[16px] xl:text-[24px] font-semibold">Storage: <span className="font-normal">{storage}TB</span></div>

                                <div className="">
                                    <div className="text-[24px] xl:text-[32px] font-bold mt-8">Price: <span className="font-normal">${price}.00</span></div>
                                </div>
                            </div>

                            <div className="w-full flex justify-center xl:justify-normal">
                                <div className="w-full flex flex-col pt-4 px-4 xl:ml-auto max-w-[500px]">
                                    <div className="text-[32px] xl:text-[40px] font-bold text-[#212121]">Shipping Information</div>
                                    <div className="mt-4 text-[16px] font-medium text-[#263238]">Contact Details</div>
                                    <div className="mt-2 flex flex-col gap-[10px]">
                                        <div className="flex flex-col sm:flex-row gap-[10px]">
                                            <Input label="First Name" onChange={(e) => setFirstName(e.target.value)} value={firstName}></Input>
                                            <Input label="Last Name" type="email" onChange={(e) => setLastName(e.target.value)} value={lastName}></Input>
                                        </div>
                                        <Input label="Email" onChange={(e) => setEmail(e.target.value)} value={email} onBlur={() => validateEmailAddress()}></Input>
                                        {!validEmail && <div className="flex flex-row items-center gap-1 text-red-500">
                                            <ExclamationTriangleIcon className="h-[20px]"/>
                                            <div className="">Please enter a valid email address.</div>
                                        </div>}
                                        
                                    </div>
                                    <div className="mt-4 text-[16px] font-medium text-[#263238]">Shipping Details</div>
                                    <div className="mt-2 flex flex-col gap-[10px]">
                                        <Input id="addressLine1" label="Address Line 1" onChange={(e) => setAddressLine1(e.target.value)} value={addressLine1}></Input>
                                        <Input label="Address Line 2" onChange={(e) => setAddressLine2(e.target.value)} value={addressLine2}></Input>
                                        <Input label="Town / City" onChange={(e) => setCity(e.target.value)} value={city}></Input>
                                        <div className="flex flex-col sm:flex-row gap-[10px]">
                                            <Input label="ZIP Code" onChange={(e) => setZIP(e.target.value)} value={zip}></Input>
                                            <Select label="State / Territory" onChange={(e) => setState(e)} value={state}>
                                                <Option value="Australian Capital Territory">Australian Capital Territory</Option>
                                                <Option value="New South Wales">New South Wales</Option>
                                                <Option value="Northern Territory">Northern Territory</Option>
                                                <Option value="Queensland">Queensland</Option>
                                                <Option value="South Australia">South Australia</Option>
                                                <Option value="Tasmania">Tasmania </Option>
                                                <Option value="Victoria">Victoria </Option>
                                                <Option value="Western Australia">Western Australia </Option>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="mt-2 flex flex-row gap-1 text-[#212121]">
                                        <InformationCircleIcon className="h-[20px]"></InformationCircleIcon>
                                        <div className="mt-[-1.5px]">At this time, we are unable to ship outside Australia</div>
                                    </div>
                                    <div className="mt-4 text-[16px] font-medium text-[#78909C]">Shipping Option</div>
                                    {shippingOption ? 
                                    <>
                                        <div className="mt-2 rounded-[8px] p-4 border border-black flex flex-row items-center align-middle">
                                            <div className="flex flex-col">
                                                <div className="font-bold">{shippingOption.title}</div>
                                                <div className="">{shippingOption.description}</div>
                                            </div>
                                            <div className="ml-auto">
                                                ${shippingOption.price}.00
                                            </div>
                                        </div>
                                    </> : 
                                    <div className="mt-2">
                                        <DefaultSkeleton/>
                                    </div>} 

                                    {!validForm && <div className="mt-2 flex flex-row items-center gap-1 text-red-500">
                                            <ExclamationTriangleIcon className="h-[20px]"/>
                                            <div className="">Please complete the form.</div>
                                        </div>}

                                    <Button variant="gradient" size="lg" className="px-4 mt-4 flex flex-row justify-center w-full" onClick={() => {
                                        if (firstName && lastName && email && addressLine1 && city && zip && state) {
                                            onPurchase({firstName: firstName, lastName: lastName, email: email, addressLines: (addressLine2 ? [addressLine1, addressLine2] : [addressLine1]), city: city, zip: zip, state: state});
                                        } else {
                                            console.log("Missing Data")
                                            setValidForm(false);
                                        }
                                    }}>
                                        Purchase
                                    </Button>
                                </div>
                            </div>

                            <div className="flex xl:hidden flex-col xl:items-center xl:flex-row-reverse ml-auto">
                                <div className="flex justify-center xl:mt-4 xl:pl-4 max-h-[400px] min-w-[200px] xl:min-w-[400px]">
                                    <img src={selectedImage} className="rounded-lg max-h-[400px]" alt="Selected Case" />
                                </div>
                                {/*Image Scroller*/}
                                <Card className="w-full mt-4 max-h-[100px] xl:max-h-none xl:w-[100px] xl:overflow-hidden " style={{ height: 450 }}>
                                    <div className={`flex flex-row xl:flex-col gap-[10px] overflow-y-auto xl:overflow-x-auto no-scrollbar`}>
                                        {variant.images.map((img, index) => (
                                            <img
                                                key={index}
                                                src={img}
                                                alt=""
                                                height={100}
                                                className={`rounded-lg cursor-pointer max-w-[100px] max-h-[100px]`}
                                                onClick={() => setSelectedImage(img)}
                                            />
                                        ))}
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </Card>
                </div>
            }
        </>
    )
}

export default Checkout;

const loadGoogleMapsScript = (callback: () => void) => {
    const existingScript = document.getElementById('googleMaps');
  
    if (!existingScript) {
        const script = document.createElement('script');
        const apiKey = process.env.REACT_APP_PLACES_API;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.id = 'googleMaps';
        document.body.appendChild(script);
  
        script.onload = () => {
            if (callback) callback();
        };
    }
  
    if (existingScript && callback) callback();
  };
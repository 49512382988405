import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@material-tailwind/react";

const Login = () => {
    const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
          appState: {
            returnTo: "/",
          },
        });
      };

    return (
        <>
        <div className='h-screen flex flex-col justify-center items-center'>
        <div className='mt-4 text-[32px] font-bold text-header'>
          Login
        </div>
        <div className='text-[16px] font-normal text-center text-content'>
          Login to access your dashboard.
          <br/>
          You will be redirected to Auth0 for authentication.
        </div>
        <div className='mt-8'>
          <Button
            variant='gradient'
            size='lg'
            className='px-[100px] bg-[#8EB5F0]'
            onClick={handleLogin}
          >
            Login
          </Button>
        </div>
      </div>
        </>
        
    );
}

export default Login;
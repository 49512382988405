import { Spinner } from "@material-tailwind/react"

interface LoadingProps {
  contentHeight?: boolean;
}

const Loading: React.FC<LoadingProps> = ({contentHeight}) => {
    return (
      <div className={`flex ${contentHeight ? "" : "h-screen"} items-center justify-center`}>
        <Spinner className="h-16 w-16" />
      </div>
    )
}

export default Loading;